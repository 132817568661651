import { hamburgerMenuButton } from "./components/hamburgerMenuButton";
import { accordion } from "./components/accordion";
// import { inputError } from "./components/inputError";
import { pageTop } from "./components/pageTop";
import { inputAutofill } from "./components/inputAutofill";

$(document).ready(function () {
  (() => {
    /*
      ハンバーガーメニュー用関数
      */
    //ハンバーガーメニューボタンクラス定義
    const buttonEl = "#hamburgerButton";
    //ハンバーガーメニュークラス定義
    const menuEl = "#hamburgerMenu";
    hamburgerMenuButton(buttonEl, menuEl);
  })();
  (() => {
    /*
      アコーディオン用関数
      */
    //アコーディオンラッパークラス定義
    const accordionEl = ".accordion";
    //アコーディオンボタンクラス定義
    const accordionButtonEl = ".accordionButton";
    //アコーディオンアイテムクラス定義
    const accordionItemEl = ".accordionItem";
    accordion(accordionEl, accordionButtonEl, accordionItemEl);
  })();
  //   (() => {
  //     /*
  //     フォームバリデーション用関数
  //     */
  //     //フォームラッパークラス定義
  //     const formEl = ".p-form";
  //     //エラー表示用クラス定義
  //     const addErrorClass = "c-error";
  //     //WPなどでフォームから出力されるエラークラスを定義
  //     const errorEl = "error";
  //     inputError(formEl, addErrorClass, errorEl);
  //   })();
  (() => {
    /*
      ページトップボタン用関数
      */
    //ボタンクラス定義
    const pageTopButtonEl = "#pageTop";

    pageTop(pageTopButtonEl);
  })();
  (() => {
    inputAutofill();
  })();
});
