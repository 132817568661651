export function accordion(accordionEl, accordionButtonEl, accordionItemEl) {
  if (
    $(accordionEl).length &&
    $(accordionButtonEl).length &&
    $(accordionItemEl).length
  ) {
    $(accordionEl).on("click", function () {
      $(this).find(accordionButtonEl).toggleClass("active");
      $(this).next($(accordionItemEl)).slideToggle();
    });
  }
}
