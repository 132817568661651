export function pageTop(pageTopButtonEl) {
  $(document).ready(function() {
    const $pageTopButton = $(pageTopButtonEl);

    $pageTopButton.click(function() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        100
      );
      return false;
    });
  });

}
